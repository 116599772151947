$font-main: 'Noto Sans KR', sans-serif;
$font-roboto: 'roboto', sans-serif;
$font-AppleNeoGD: 'Apple Sandoll Gothic Neo';

.roboto {
  font-family: $font-roboto;
}
.appleNeoGD {
  font-family: $font-AppleNeoGD;
}
