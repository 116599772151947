// @import './constant/spaces'; // 주석 제거 시 삭제
@import './constant/typography';

@import './base/fonts';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
  scroll-behavior: smooth;
  scroll-behavior: auto !important;
  word-break: keep-all;
}

body {
  // min-width: 1200px;
  // background-color: #ccc;
  // overflow: hidden;
}

// for pin
html,
body {
  margin: 0;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
